.card-style {
  border-radius: 12px;
  box-shadow: inset 5px -5px 10px #e1e1e1,
    inset -1px 1px 5px #ffffff;
}

.nav-Shadow {
  box-shadow: 0px 0px 10px 0px #c3c3c3
}

.btn-note {
  box-shadow: 0px 5px 8px #0ec683 inset;
}

.btn-refund {
  box-shadow: 0px 5px 8px #3f8abf inset;
}

.btn-info {
  box-shadow: 0px 5px 8px #1a98be inset;
}

.btn-shadow {
  background: #F1F4F6;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px #FFFFFF;
  border-radius: 5px;
}

.payment-shadow {
  background: #1083B2;
  box-shadow: inset 4px 4px 15px rgba(0, 0, 0, 0.25);
  /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px #FFFFFF; */
  /* border-radius: 5px 0px 0px 5px; */
}

.custom-dropdown .ant-select-item-option {
  background-color: #0ec683;
}

.icon-input {
  background-image: url('http://cdn.onlinewebfonts.com/svg/img_184513.png');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 20px;
  padding-left: 25px;
  /* adjust the padding to leave space for the icon */
}



/* sider style css */
/* #app {
  height: 100%;
}

html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */